import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import SmokeEffect from "./smokeEffect";
import ParticleEffect from "./smokeEffect";
import NavigationScene from "./smokeEffect";

function App() {
  const [film, setFilm] = useState("LkufozluseI&t=963s");
  const [data, setData] = useState(null);
  const [string, setString] = useState(null);
  useEffect(() => {
    axios.get(`https://srobh.rawad.net/${film}`).then((res) => {
      setData(res.data);
    });
  }, []);
  function scrollToEnd(duration) {
    const initialPosition = window.scrollY;
    const distance = initialPosition + 15;
    function scroll() {
      window.scrollTo({
        top: distance,
        behavior: "smooth",
      });
    }

    requestAnimationFrame(scroll);
  }

  // Call the function with the desired duration (e.g., 2000 ms for 2 seconds)

  useEffect(() => {
    axios
      .get(`https://srobh.rawad.net/${film}`)
      .then((res) => setData(res.data));
  }, [film]);
  useEffect(() => {
    dataTostring();
  }, [data]);
  function dataTostring() {
    let t = "";
    data &&
      data.map((e, index) => {
        const deutsch = e.ar.map((x) => {
          let t = +x.hasOwnProperty("segs") && x?.segs.map((m) => m.utf8);
          return t;
        });
        t += deutsch;
      });
    setString(t);
  }

  useEffect(() => {
    const t = setInterval(() => {
      scrollToEnd();
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(t);
    };
  }, []);
  return (
    <div className="container overflow-hidden flex-col items-center justify-center">
      <label>Select an option:</label>
      <select onChange={(e) => setFilm(e.target.value)}>
        <option value="">Select...</option>
        <option value="option1">A1</option>
        <option value="Lg5P2w_Ro1c&t=5996s">A2</option>
        <option value="LkufozluseI&t=963s">B1</option>
        <option value="pyBRXIwXSkM">podcast</option>
      </select>
      <div
        className={
          "w-full md:w-6/12 items-center justify-center flex flex-col gap-2"
        }
      >
        {data &&
          data.map((e, index) => {
            const deutsch = e.de.map((x) => {
              let t = +x.hasOwnProperty("segs") && x?.segs.map((m) => m.utf8);
              return t;
            });
            const arabic = e.ar.map((x) => {
              let t = +x.hasOwnProperty("segs") && x?.segs.map((m) => m.utf8);
              return t;
            });
            return (
              <div key={index} className={"gap-2 bg-green-200 w-full"}>
                <span
                  className={"bg-blue-300 block p-2 text-black"}
                  dangerouslySetInnerHTML={{ __html: arabic }}
                ></span>
                <span
                  className={"bg-rose-900 block bg-opacity-5 p-2 text-black"}
                  dangerouslySetInnerHTML={{ __html: deutsch }}
                ></span>
              </div>
            );
          })}
      </div>
      {string && (
        <p className={"text-5xl whitespace-pre-wrap"}>
          {string.replaceAll("– ", "\n")}
        </p>
      )}
      {/*<NavigationScene />*/}
    </div>
  );
}

export default App;
